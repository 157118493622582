@import './mixins';

.form-pop-container {
  background: var(--light-primary-bg-color);
  padding: 30px 30px 120px 30px;
  overflow-y: auto;
}

.form-pop-wrapper {
  width: 1000px;
  margin: 0px auto 70px;
}

.form-pop-wrapper > h1 {
  font-size: 32px;
  color: var(--light-main-color);
  line-height: 48px;
  margin-bottom: 32px;
  text-align: center;
  border-width: 0px 0px 1px 0px;
  font-family: "Recoleta";
  font-weight: 400;
}

.form-fields-group {
  @include flx-col();
  gap: 24px;
}

.group-head-1 {
  background-color: var(--white);
  padding: 24px;
  border-radius: 6px;
}

.group-head-1 p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.group-heading {
  @include flx-row-sb();
}

.group-head-1 > h4 > .required {
  font-size: 16px;
  color: var(--light-button-primary-bg-color);
}

.group-head-1 > .subtitle-sml-txt {
  color: var(--light-main-color);
  margin-bottom: 24px;
}
.group-head-1 > h4 {
  font-size: 18px;
  color: var(--light-main-color);
  margin: 0px;
  @include flx-bl-sb();
  margin-bottom: 12px;
}

.set-1 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}

.set-2 {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 12px));
  gap: 24px;
}
.set-3 {
  display: grid;
  grid-template-columns: repeat(3, calc(33.3% - 16px));
  gap: 24px;
}

.set-2 > .full {
  grid-column-end: 3;
  grid-column-start: 1;
}

.add-row {
    font-size: 14px;
    padding: 8px 12px;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    background: var(--table-btn-bg);
    color: var(--light-button-primary-bg-color);
    border-radius: 4px;
    .txt {
      margin-top: 2px;
    }
  }

  img.delete-row {
    display: none;
    position: absolute;
    width: 20px;
    right: -20px;
    top: 15px;
    cursor: pointer;
  }
  
  
  tr:hover .delete-row {
    display: block;
  }

.accordion {
  border-radius: 6px;
  background: var(--white);
  // box-shadow: 0px 1px 3px 0px var(--box-shadow-color-2), 0px 1px 2px 0px var(--box-shadow-color-3);



  &.expanded {
    .accordion-content {
      max-height: 3000px;
      padding: 0 24px 24px;
      transition: max-height 1s ease-out;
      overflow: visible;
    }

    .rotate-arrow {
      transform: rotate(180deg);
    }
  }

  .accordion-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 24px;
    background-color: var(--white);

    .accordion-heading {
      display: flex;
      flex-direction: column;
    }
    .icons {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }

    .accordion-title {
      flex: 1;
      font-size: 18px;
      color: var(--light-main-color);
    }

    .arrow-icon {
      font-size: 24px;
    }
  }

  .accordion-sub-txt {
    color: var(--light-secondary-color);
    margin-top: 6px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 200;
  }

  .accordion-content {
    max-height: 0;
    overflow: hidden;
  }
}

.form-footer {
  position: fixed;
  width: 100%;
  bottom: 0px;
  background: var(--white);
  border: 1px solid var(--white);
  box-shadow: 0px 5px 20px var(--box-shadow-color);
  border-radius: 8px 8px 0px 0px;
  padding: 24px;
  gap: 12px;
  @include flx-col-cntr-sb();
  /* grid-template-columns: 140px 1fr 140px; */
  left: 50%;
  transform: translateX(-50%);
  .container {
    width: 100%;
    @include flx-cntr-sb();
  }
}
.back-btn {
  min-width: 140px;
  color: var(--light-button-primary-bg-color);
  background-color: var(--white);
  border: 1px solid var(--light-button-primary-bg-color-hover);
  border-radius: 4px;
  outline: 0;
  padding: 8px;
  line-height: 21px;
  cursor: pointer;
}
.submit-btn {
  min-width: 140px;
  color: var(--white);
  background-color: var(--light-button-primary-bg-color);
  border: 1px solid var(--form-button-color);
  border-radius: 4px;
  outline: 0;
  padding: 8px;
  line-height: 21px;
  cursor: pointer;
}
.submit-btn:hover {
  background-color: var(--light-button-primary-bg-color-hover);
  border-color: var(--light-button-primary-bg-color-hover);
}

.submit-btn.sending {
  cursor: progress !important;
}

.step-detail {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  color: var(--light-main-color);
}

.steppers {
    @include flx-row();
    gap: 4px;
  .stepper {
    width: 49px;
    height: 12px;
    border-radius: 16px;
  }
  
  .valid {
    background-color: var(--stepper-bg-active);
  }
  
  .invalid {
    background-color: var(--stepper-bg);
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: left;
  .password-field {
    display: flex;
    justify-content: space-between;
    a {
      text-decoration: underline;
      color: var(--highlight-color);
      cursor: pointer;
    }
  }
  .show-password {
    width: 19px;
    position: absolute;
    top: 42px;
    right: 10px;
    cursor: pointer;
  }
  .login-btn {
    background-color: var(--light-button-primary-bg-color);
    border: 0px;
    outline: none;
    width: 100%;
    border-radius: 6px;
    padding: 10px;
    font-size: 18px;
    line-height: 25px;
    color: var(--white);
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    &:hover {
      background-color: var(--light-button-primary-bg-color-hover);
    }
  }
  .error-message, .success-message {
    font-size: 14px;
    line-height: 16px;
    margin: 0px;
    display: flex;
    align-items: center;
    gap: 5px;
    .msg {
      margin-top: 4px;
    }
  }
  .error-message {
    color: var(--red);
  }
  .success-message {
    color: var(--highlight-color);
  }
  .valid-icons {
    width: 20px; 
  }
  .confirm-check {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p {
      line-height: 26px;
    }
  }
}

.sign-img-cnt {
  .sign-img {
    width: 400px;
    max-width: 400px;
    height: 110px;
    object-fit: contain;
  }
}

.sign-btns {
  display: flex;
  gap: 24px;
  .sign-btn {
    width: auto;
  }
  .clear-btn {
    width: auto;
  }
}
