@mixin flx-col {
    display: flex;
    flex-direction: column;
}
@mixin flx-row {
    display: flex;
    flex-direction: row;
}
@mixin flx-row-cntr {
    @include flx-row();
    justify-content: center;
}
@mixin flx-col-cntr {
    @include flx-col();
    justify-content: center;
}
@mixin flx-row-sb {
    @include flx-row();
    justify-content: space-between;
}
@mixin flx-col-sb {
    @include flx-col();
    justify-content: space-between;
}
@mixin flx-cntr {
    display: flex;
    justify-content: center;
}
@mixin flx-cntr-cntr {
    @include flx-cntr();
    align-items: center;
}
@mixin flx-cntr-sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@mixin flx-bl-sb {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
@mixin flx-cntr-strt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
@mixin flx-cntr-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
@mixin flx-strt-strt {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
@mixin flx-col-cntr-sb {
    @include flx-cntr-sb();
    flex-direction: column;
}
@mixin flx-col-cntr-strt {
    @include flx-col();
    @include flx-cntr-strt();
}
@mixin flx-col-cntr-cntr {
    @include flx-cntr-cntr();
    flex-direction: column;
}
@mixin flx-row-cntr-sb {
    @include flx-cntr-sb();
    flex-direction: row;
}