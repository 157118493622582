.primary-btn {
  width: 140px;
  background-color: var(--light-button-primary-bg-color);
  color: var(--white);
  height: 40px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid var(--light-button-primary-bg-color);
  outline: 0;
  transition:
    width 2s,
    height 4s;
  &:hover {
    background: var(--light-button-primary-bg-color-hover);
  }
}

.save-btn:hover:not(:disabled),
.add-serv:hover:not(:disabled) {
  background-color: var(--main-btn-light-bg) !important;
  color: var(--light-button-primary-bg-color) !important;
  border: 1px solid var(--light-button-primary-bg-color) !important;
}

.transparent-btn {
  width: 100%;
  border-radius: 6px;
  height: 47px;
  outline: 0;
  background-color: transparent;
  color: var(--light-main-color);
  border: 1px solid var(--light-main-color);
}

.discard-button {
  height: 40px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid var(--light-button-primary-bg-color);
  outline: 0px;
  transition:
    width 2s,
    height 4s;
  width: 80px;
  margin-right: 20px;
  background-color: transparent;
  color: var(--light-button-primary-bg-color);
}

.discard-button:hover {
  background: var(--light-button-hover-bg-color-1);
}

.action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  .action-icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    button {
      padding: 0;
      border: 0;
      background: transparent;
      outline: 0;
      img {
        width: 16px;
        height: 16px;
        margin-top: 2px;
      }
      i {
        margin-top: 2px;
        color: var(--light-main-color);
      }
    }
    &.action-icon-edit:hover {
      background-color: var(--light-button-hover-bg-color-2);
      border-radius: 32px !important;
    }
    &.action-icon-delete:hover {
      background-color: var(--table-btn-bg);
      border-radius: 32px !important;
    }
    &.action-icon-view:hover {
      background-color: var(--light-button-hover-bg-color-2);
      border-radius: 32px !important;
    }
  }
}
