:root {
  --content-padding: 0px 0px;
  --content-max-width: 1100px;
  --content-dashboard-max-width: 1100px;
  --card-content-max-width: 805px;
  --light-primary-bg-color: #F4F4F0;
  --light-border-color: #00000019;
  --light-main-color: #063E3B;
  --light-secondary-color: #6E8886;
  --light-secondary-color-2: #063E3B;
  --light-sub-text-color: #00000026;
  --box-shadow-color: #00000029;
  --box-shadow-color-2: #0000001A;
  --box-shadow-color-3: #0000000F;
  --light-button-primary-bg-color: #F1490D;
  --form-button-color: #FC5E23;
  --form-button-bg: #FDE9E3;
  --light-button-primary-bg-color-hover: #FD4400;
  --light-card-bg-color: #FCFDFC;
  --light-button-hover-bg-color-1: #E9E9DD;
  --light-button-hover-bg-color-2: #E0EAE6;
  --light-border-color-2: #C5C5AD;
  --highlight-color: #00bd71;
  --border-warn-color: #efd9d9;
  --dropdown-bg: #f8f9fa;
  --main-btn-light-bg: #fef4f0;
  --breadcrumb-color: #5B7977;
  --breadcrumb-bg: #547775;
  --breadcrumb-active: #5b797780;
  --stepper-bg: #CDDEDA;
  --stepper-bg-active: #095D59;
  --table-border: #F4F4F5;
  --button-border: #CCC;
  --button-bg: #F2E4DC;
  --table-btn-bg: #FDE6DD;
  --table-btn: #fd896b;
  --table-bg: #6E88860D;
  --table-bg-2: #6E888633;
  --recommended-color: #C5F864;
  --value-color: #6d8886;
  --badge-bg: #DCFCE7;
  --status-completed-color: #166534;
  --status-na-color: #606060;
  --status-na-bg: #E0E0E0;
  --status-cnfm-bg: #0E01A11A;
  --status-cnfm-color: #0E01A1;
  --status-cncl-bg: #B61B441A;
  --status-cncl-color: #B61B44;
  --status-form-due-color: #A17401;
  --status-form-due-bg: #FFFEDB;


  --nav-active: #b9c7bb4d;
  --card-box-shadow: #095d594d;

  --green-icon: #3C704E;

  --white: #fff;
  --red: #ff0000;
  --blue: #3D2DFE;
  --blue-hover: #DCD9FF;

  --booking-max-width: 530px;

  --f1490d : brightness(0) saturate(100%) invert(41%) sepia(65%) saturate(4506%) hue-rotate(354deg) brightness(95%) contrast(100%);
}
