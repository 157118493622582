@import './mixins';

.mobile-center {
  @include flx-cntr-cntr();
  background-color: var(--light-primary-bg-color);
  height: 80dvh;
}

.mobile-content-center {
  @include flx-col-cntr-cntr();
  text-align: center;
  gap: 15px;
  i.fa {
    @include flx-cntr-cntr();
    text-align: center;
    color: var(--light-button-primary-bg-color);
    border-radius: 50%;
    border: 2px solid var(--light-button-primary-bg-color);
    width: 30px;
    height: 30px;
  }
  .icon {
    filter: brightness(0) saturate(100%) invert(29%) sepia(8%) saturate(4929%) hue-rotate(131deg) brightness(92%) contrast(93%);
  }
  .heading-txt-2 {
    color: var(--light-button-primary-bg-color);
  }
  .subtitle-sml-txt {
    color: var(--light-main-color);
    margin: 0px;
  }
}

.about-group {
  padding: var(--content-padding);
  .about-wrapper {
    padding: 70px 0px;
    width: var(--content-max-width);
    margin: 0 auto;
    h1 {
      font-size: 36px;
      font-family: 'Recoleta';
      font-weight: 400;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 15px;
    }
    p {
      font-size: 16px;
      font-weight: 200;
      line-height: 24px;
      margin-bottom: 20px;
    }
    a {
      color: var(--highlight-color);
    }
    ol, ul {
      padding-left: 17px;
    }
  }
}

.ct-breadcrumbs  {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  position: relative;
  margin-bottom: 1px;
  li {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    &:not(:last-child):after {
      content: "";
      width: 3px;
      height: 3px;
      position: absolute;
      background-color: var(--breadcrumb-bg);
      border-radius: 50%;
      right: -9px;
      top: 7px;
    }
    a {
      cursor: pointer;
      text-decoration: none;
      color: var(--light-main-color);
    }
    &:last-child {
      a {
        color: var(--highlight-color);
      }
    }

  }
}

.title-head {
  width: 100%;
  max-width: 930px;
  margin: 30px auto 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  h2 {
    font-size: 22px;
    color: var(--light-main-color);
    font-weight: 400;
    line-height: 30px;
  }
  .arrow-btn {
    &:hover:not(:disabled) {
      background-color: var(--light-border-color-2);
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

.testing-info {
  font-size: 14px;
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.card-container {
  background: var(--light-primary-bg-color);
  max-height: 600px;
  min-height: 600px;
  border: 1px solid var(--light-border-color);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  overflow: auto;
  .heading {
      display: flex;
      position: sticky;
      top: 0;
      z-index: 99;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      background: linear-gradient(135deg, #ffffff, #f3f4f6);
      border-bottom: 1px solid var(--light-border-color-2);
      border-radius: 12px 12px 0 0;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

      .left {
          display: flex;
          flex-direction: column;
          .title {
              font-size: 1.5rem;
              font-weight: 600;
              color: var(--light-main-color);
              margin: 0;
          }
      }
  }
  .slides {
      .no-appointments {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 100px auto;
          width: 128px;
          p {
            width: 200px;
            text-align: center;
          }
      }

      .slide {
          padding: 20px;
          margin: 8px 16px;
          display: grid;
          grid-template-columns: 1fr auto;
          justify-content: space-between;
          gap: 16px;
          background: var(--light-card-bg-color);
          border-radius: 10px;
          box-shadow: 0 2px 8px var(--box-shadow-color-3);
          transition: transform 0.2s, box-shadow 0.2s;
          &:hover {
              transform: translate(4px);
              box-shadow: 0 4px 16px var(--box-shadow-color-2);
              cursor: pointer;
          }
          .left {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 16px;
              .date {
                  background: var(--form-button-bg);
                  color: var(--form-button-color);
                  padding: 12px;
                  border-radius: 8px;
                  font-weight: 700;
                  text-align: center;
              }
              .app-content {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  .time {
                      font-size: 1rem;
                      font-weight: 600;
                      color: var(--light-main-color);
                      display: flex;
                      align-items: center;
                      gap: 8px;
                      .badge {
                          margin-left: 8px;
                          padding: 4px 10px;
                          border-radius: 12px;
                          font-size: 0.75rem;
                          font-weight: bold;
                          &.completed {
                              background-color: var(--badge-bg);
                              color: var(--status-completed-color);
                          }
                          &.not-applicable{
                              background-color: var(--status-na-bg);
                              color: var(--status-na-color);
                          }
                          &.payment-due,
                          &.pending-form,
                          &.arrived,
                          &.form-due {
                              color: var(--status-form-due-color);
                              background-color: var(--status-form-due-bg);
                          }
                          &.pending {
                              background-color: var(--light-primary-bg-color);
                              color: var(--light-secondary-color);
                          }
                          &.submitted,
                          &.confirmed {
                              background-color: var(--status-cnfm-bg);
                              color: var(--status-cnfm-color);
                          }
                          &.form-overdue,
                          &.overdue,
                          &.no-show,
                          &.reschedule,
                          &.rescheduled,
                          &.cancelled {
                              background-color: var(--status-cncl-bg);
                              color: var(--status-cncl-color);
                          }
                      }
                  }
                  .service, .type {
                      font-size: 0.9rem;
                      font-weight: 500;
                      color: var(--light-secondary-color);
                      display: flex;
                      align-items: center;
                      gap: 8px;
                  }
              }
          }
          .right {
              display: flex;
              align-items: center;
              font-size: 1.2rem;
              color: var(--light-primary-color);
              font-weight: 700;
          }
      }
  }
}
.text-center {
  text-align: center;
}
