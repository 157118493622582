@font-face {
  font-family: "Recoleta";
  font-weight: 100;
  src: url('webFonts/RecoletaThin/font.woff2') format('woff2'), url('webFonts/RecoletaThin/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Recoleta";
  font-weight: 200;
  src: url('webFonts/RecoletaLight/font.woff2') format('woff2'), url('webFonts/RecoletaLight/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Recoleta";
  font-weight: 400;
  src: url('webFonts/RecoletaRegular/font.woff2') format('woff2'), url('webFonts/RecoletaRegular/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Recoleta";
  font-weight: 500;
  src: url('webFonts/RecoletaMedium/font.woff2') format('woff2'), url('webFonts/RecoletaMedium/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Recoleta";
  font-weight: 700;
  src: url('webFonts/RecoletaSemiBold/font.woff2') format('woff2'), url('webFonts/RecoletaSemiBold/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Recoleta";
  font-weight: 800;
  src: url('webFonts/RecoletaBold/font.woff2') format('woff2'), url('webFonts/RecoletaBold/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Recoleta";
  font-weight: 900;
  src: url('webFonts/RecoletaBlack/font.woff2') format('woff2'), url('webFonts/RecoletaBlack/font.woff') format('woff');
  font-display: swap;
}



@font-face {
  font-family: "Avenir";
  font-weight: 100;
  src: url('webFonts/Avenir35Light/font.woff2') format('woff2'), url('webFonts/Avenir35Light/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  font-style: italic;
  font-weight: 100;
  src: url('webFonts/Avenir35LightOblique/font.woff2') format('woff2'), url('webFonts/Avenir35LightOblique/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  font-weight: 200;
  src: url('webFonts/Avenir45Book/font.woff2') format('woff2'), url('webFonts/Avenir45Book/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  font-style: italic;

  src: url('webFonts/Avenir45BookOblique/font.woff2') format('woff2'), url('webFonts/Avenir45BookOblique/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  font-weight: 300;
  src: url('webFonts/Avenir55Roman/font.woff2') format('woff2'), url('webFonts/Avenir55Roman/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  font-weight: 300;
  font-style: italic;
  src: url('webFonts/Avenir55Oblique/font.woff2') format('woff2'), url('webFonts/Avenir55Oblique/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  font-weight: 400;
  src: url('webFonts/Avenir65Medium/font.woff2') format('woff2'), url('webFonts/Avenir65Medium/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  font-style: italic;
  font-weight: 400;
  src: url('webFonts/Avenir65MediumOblique/font.woff2') format('woff2'), url('webFonts/Avenir65MediumOblique/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  font-weight: 700;
  src: url('webFonts/Avenir85Heavy/font.woff2') format('woff2'), url('webFonts/Avenir85Heavy/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  font-style: italic;
  font-weight: 700;
  src: url('webFonts/Avenir85HeavyOblique/font.woff2') format('woff2'), url('webFonts/Avenir85HeavyOblique/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  font-weight: 900;
  src: url('webFonts/Avenir95Black/font.woff2') format('woff2'), url('webFonts/Avenir95Black/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  font-style: italic;
  font-weight: 900;
  src: url('webFonts/Avenir95BlackOblique/font.woff2') format('woff2'), url('webFonts/Avenir95BlackOblique/font.woff') format('woff');
  font-display: swap;
}
