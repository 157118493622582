/* You can add global styles to this file, and also import other style files */
@import url(./assets/fonts/webfonts.css);
@import './styles/avn_main';

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-unselected-icon-color: #A1A1AA;
  --mdc-checkbox-unselected-hover-icon-color: #00BD71;
  --mdc-checkbox-selected-icon-color: #00BD71;
  --mdc-checkbox-selected-icon-color: #00BD71;
  --mdc-checkbox-selected-pressed-icon-color: #00BD71;
  --mdc-checkbox-selected-focus-icon-color: #00BD71;
  --mdc-theme-text-primary-on-background: #00BD71;
  --mdc-checkbox-selected-hover-icon-color: #00BD71;
  --mdc-checkbox-selected-checkmark-color: #063E3B;
  --mdc-checkbox-unselected-focus-icon-color: rgba(0, 0, 0, 0.38);
}
.mdc-checkbox__background{
  border-radius: 4px !important;
}
.mat-mdc-radio-button .mdc-form-field,
.mat-mdc-checkbox .mdc-form-field  {
  --mdc-theme-text-primary-on-background: #063E3B;
}
.mdc-radio__outer-circle {
  --mdc-radio-unselected-icon-color: #063E3B;
}
.mdc-form-field>label {
  padding-top: 4px;
}

body {
  background-color: var(--light-primary-bg-color);
  font-family: "Avenir", sans-serif;
  font-weight: 200;
  color: var(--light-main-color);
}

* {
  font-family: "Avenir", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
}

.fa {
  font-family: "Font Awesome 6 Free" !important;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #063E3B;
}
.mat-calendar-arrow {
  fill: var(--light-main-color);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.mat-calendar-table-header,
.mat-calendar-body-label,
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: var(--light-main-color);
}

.mdc-notched-outline {
  border-radius: 0px;
  border: 0 !important;
}

.mat-datepicker-toggle .mdc-icon-button {
  margin-top: -8px;
}

.mat-mdc-form-field {
  width: -webkit-fill-available;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}

.mat-mdc-option:hover {
  background-color: rgba(185, 199, 187, 0.3) !important;
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: none;
}

.multi-custom {
  .mat-mdc-option.mdc-list-item {
    margin-bottom: 15px;
  }
}

.mat-mdc-tab-body-content {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  flex: auto;
  gap: 13px 21px;
}

.mt-select-dropdown {
  background: var(--light-border-color-2);
}

.mat-mdc-text-field-wrapper {
  height: 47px !important;
}

.mat-icon {
  height: 16px;
  width: 16px;
}

.highlighted {
  color: var(--highlight-color);
}

button {
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}

/* =============================================
Material common styles
=============================================*/

.mat-mdc-menu-content {
  padding: 0px 0px !important;
}

.custom-dropdown {
  width: 258px;
  @include flx-col();
  padding: 10px 10px;
  gap: 8px;
  button {
    padding: 9px 8px;
    background-color: transparent;
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    text-align: left;
    border: 0px;
    outline: 0px;
    @include flx-cntr-sb();
    color: var(--light-main-color);
    &:hover {
      background-color: var(--light-primary-bg-color);
    }
  }
  .divider {
    border-top: 1px solid var(--light-button-hover-bg-color-1);
    margin: 0px -10px;
  }
}

.mat-mdc-menu-panel {
  box-shadow: 0px 0px 16px var(--box-shadow-color) !important;
  border-radius: 10px !important;
  margin-top: 20px !important;
  &.booking-panel {
    margin-top: 0px !important;
  }
}
.filter-menu {
  margin-top: 0px !important;
}
.mat-calendar-body-cell:not(.mat-calendar-body-in-range) {
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: var(--light-main-color);
    color: var(--light-main-color);
  }
  .mat-calendar-body-cell-content {
    color: var(--light-main-color);
    &.mat-calendar-body-selected {
      color: var(--white);
    }
  }
}
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--light-main-color);
  color: #FFF;
}

.mat-mdc-optgroup-label .mdc-list-item__primary-text {
  color: #9BB2B1;
}

@media screen and (max-width: 1100px) {
  :root {
    --content-max-width: 100% !important;
    --card-content-max-width: 100% !important;
    --content-padding: 0px 17px !important;
  }
  .field-input,
  .mat-mdc-select {
    width: 100%;
    border-radius: 4px;
  }

  .field-input {
    font-size: 16px;
    padding: 12px 8px 11px;
  }

  .mat-mdc-select-placeholder,
  ::placeholder {
    color: var(--light-secondary-color);
    font-weight: 200;
  }
  .custom-select {
    margin: 8px 0px 0px -8px !important;
    .mdc-list-item__primary-text {
      color: var(--light-main-color);
    }
  }
}


.search-filter-group {
  .mat-mdc-select {
    width: 137px;
    .mat-mdc-select-placeholder {
      color: var(--light-secondary-color);
      font-weight: 200;
    }
    .mat-mdc-select-trigger {
      border-radius: 4px;
      border: 1px solid var(--light-button-hover-bg-color-1);
      padding: 9px 10px;
      font-size: 14px;
    }
  }
}
.custom-filter-select {
  max-height: 200px !important;
  .mat-mdc-option {
    min-height: 30px !important;
  }
  .mdc-list-item__primary-text {
    font-size: 14px !important;
    --mdc-theme-text-primary-on-background: #063E3B;
  }
}

.mdc-list-item__primary-text {
  --mdc-theme-text-primary-on-background: #063E3B;
}
.mat-pseudo-checkbox-full {
  color: #063E3B !important;
  border-width: 1px !important;
  border-radius: 4px !important;
}

.filter-select {
  .mat-mdc-select-trigger {
    border-radius: 6px !important;
    font-size: 18px !important;
    line-height: 25px !important;
    padding: 9px 14px !important;
    border: 1px solid var(--light-border-color-2) !important;
  }
  &:hover {
    background-color: var(--light-border-color-2);
    border-radius: 8px;
  }
}
.field-type-select {
  .mat-mdc-select-placeholder {
    color: var(--light-secondary-color);
    font-weight: 200;
  }
  .mat-mdc-select-trigger {
    border-radius: 4px;
    border: 1px solid var(--light-secondary-color);
    padding: 9px 22px 9px 8px;
    font-size: 14px;
  }
  .mat-mdc-select-arrow {
    width: 10px !important;
    height: 10px !important;
    border: 2px solid #063E3B;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    top: 13px;
    right: 12px;
    position: absolute;
    svg {
      display: none;
    }
  }
  &[aria-expanded="true"] {
    .mat-mdc-select-arrow {
      transform: rotate(225deg);
      top: 17px;
    }
  }
  &.invalid {
    .mat-mdc-select-trigger {
      border: 1px solid red;
    }
    .mat-mdc-select-value-text {
      color: red;
    }
    .mat-mdc-select-arrow {
      border-color: red;
    }
  }
  &.filter-select {
    .mat-mdc-select-arrow {
      bottom: 2px;
    }
    &[aria-expanded=true] {
      .mat-mdc-select-arrow {
        bottom: -2px;
      }
    }
  }
}

.field-type-select-white {
  .mat-mdc-select-placeholder {
    color: var(--light-secondary-color);
    font-weight: 200;
  }
  .mat-mdc-select-trigger {
    border-radius: 8px;
    border: 1px solid #C5C5AD;
    background-color: #FFF;
    padding: 9px 22px 9px 8px;
    font-size: 14px;
  }
  .mat-mdc-select-arrow {
    width: 10px !important;
    height: 10px !important;
    border: 2px solid #063E3B;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    top: 13px;
    right: 12px;
    position: absolute;
    svg {
      display: none;
    }
  }
  &[aria-expanded="true"] {
    .mat-mdc-select-arrow {
      transform: rotate(225deg);
      top: 17px;
    }
  }
  &.invalid {
    .mat-mdc-select-trigger {
      border: 1px solid red;
    }
    .mat-mdc-select-value-text {
      color: red;
    }
    .mat-mdc-select-arrow {
      border-color: red;
    }
  }
  &.filter-select {
    .mat-mdc-select-arrow {
      bottom: 2px;
    }
    &[aria-expanded=true] {
      .mat-mdc-select-arrow {
        bottom: -2px;
      }
    }
  }
}

.incident-option {
  width: 100%;
  .mdc-list-item__primary-text {
    margin: 0px 0px 0px 0px !important;
    width: 100%;
  }
}

.custom-field-type-select {
  margin-top: -1px;
  box-shadow: none;
  border: 1px solid var(--light-main-color);
  padding: 0px !important;
  --mdc-theme-surface: #F4F4F0;
  --mdc-theme-text-primary-on-background: #063E3B;
  .mdc-list-item__primary-text {
    margin: 0px 0px 0px 0px;
    width: 100%;
  }
  .service-group-option {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    .value:last-child {
      white-space: nowrap;
      display: flex;
      align-items: baseline;
      gap: 4px;
      .discount {
        color: red;
        text-decoration: line-through;
        font-size: 12px;
      }
    }
  }
  .mat-mdc-optgroup {
    .mat-mdc-optgroup-label {
      position: sticky;
      background-color: var(--light-primary-bg-color);
      top: 0px;
      z-index: 1;
    }
  }
  &.white {
    --mdc-theme-surface: #FFFFFF;
    border: 0px;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.10), 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
    .mat-mdc-optgroup {
      .mat-mdc-optgroup-label {
        background-color: #FFFFFF;
      }
    }
  }
}


@media screen and (max-width: 900px) {
  .service-group-option {
    flex-direction: column;
    gap: 5px !important;
    .value:last-child {
      font-size: 13px;
    }
  }
}

.field-mandatory {
  color: var(--light-button-primary-bg-color);
}
.field-type-1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  .field-label {
    color: var(--light-main-color);
    font-size: 16px;
    font-weight: 200;
    margin: 0px;
    .fa {
      margin-left: 10px;
    }
    .field-mandatory {
      color: var(--light-button-primary-bg-color);
    }
  }
  .field-disclaimer {
    color: var(--light-main-color);
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 200;
  }

  .field-input {
    background-color: transparent;
    border: 1px solid var(--light-secondary-color);
    border-radius: 4px;
    padding: 12px 10px;
    outline: none;
    color: var(--light-main-color);
    font-size: 16px;
    height: 44px;
    &::placeholder {
      color: var(--light-secondary-color);
      font-size: 16px;
      font-weight: 200;
    }
    &:hover,
    &:focus {
      border-color: var(--light-main-color);
    }
    &.dropdown-toggle {
      cursor: pointer;
      &.show {
        border-radius: 4px 4px 0px 0px;
        border-bottom-color: transparent !important;
        border-color: var(--light-secondary-color);
      }
    }
  }

  textarea.field-input {
    height: 160px;
    resize: none;
    font-family: 'Avenir';
    font-weight: 200;
  }

  .field-checkbox {
    margin-left: -10px;
    .mdc-form-field {
      gap: 10px;
    }
  }

  .field-count {
    position: absolute;
    bottom: 7px;
    font-size: 12px;
    right: 3px;
  }

  &.has-error {
    .field-label {
      color: var(--red) !important;
    }
    .field-input {
      border: 1px solid var(--red) !important;
      background-color: #ffbaba4a !important;
    }
  }

  &.disabled {
    .field-label, .field-input {
      opacity: .4;
    }
  }

  ul.errors {
    list-style: none;
    padding: 0px;
    .error-message {
      font-size: 12px;
      color: #F00;
    }
  }
}

.field-type-1 .field-label {
  color: var(--light-main-color);
  font-size: 16px;
  line-height: 22px;
}

.field-type-1 .field-mandatory {
  color: var(--light-button-primary-bg-color);
}
.integration-input {
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--light-button-hover-bg-color-1);
  padding: 7px 10px;
  font-size: 16px;
  border-radius: 4px;
  outline: 0px;
  color: var(--light-main-color);
}

.field-type-1 .field-description {
  color: var(--light-secondary-color);
  margin: 0px;
  font-size: 16px;
  line-height: 22px;
  font-style: italic;
}


.field-type-1 .field-input::placeholder {
  color: var(--light-secondary-color) !important;
  font-weight: 200;
}

.field-type-1.with-border .field-input {
  border-color: #6e888657;
}

.field-type-1 .field-input.dropdown-toggle {
  cursor: pointer;
  padding: 7px 30px 7px 10px;
}

.field-type-1 .field-input.dropdown-toggle.show {
  border-radius: 4px 4px 0px 0px;
  border-color: var(--light-secondary-color);
  border-bottom-color: transparent;
}

.field-type-1 .dropdown-menu {
  transform: none !important;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid var(--light-secondary-color);
  border-width: 0px 1px 1px;
  padding: 0px 10px;
  top: calc(100% - 1px) !important;
  width: 100%;
  min-width: 100%;
  background-color: var(--light-primary-bg-color);
  display: none;
  position: absolute;
  z-index: 99;
  height: auto;
  inset: 0px auto auto 0px;
  margin: 0px;
  &.show {
    display: block;
  }
}

.field-type-1 .dropdown-menu > ul {
  list-style: none;
  margin: 0px;
  border: 1px solid #CCDBCF;
  border-width: 1px 0px 0px 0px;
  padding: 6px 0px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-height: 250px;
  overflow-y: auto;
}

.field-type-1 .dropdown-menu>ul>li {
  padding: 5px;
  font-size: 16px;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  color: var(--light-main-color);
}

.field-type-1 .dropdown-menu>ul>li:hover, .field-type-1 .dropdown-menu>ul>li.selected {
  background-color: #DAE5E2;
}

.pointer {
  cursor: pointer;
}

button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.mega-menu-backdrop {
  background-color: #00000080;
}
.mobile-notification-menu-panel, .mobile-mega-menu-panel, .mega-menu-panel, .reschedule-pop-modal, .cancel-pop-modal {
  .mat-mdc-dialog-surface {
    --mdc-dialog-container-shape: 12px;
    --mdc-dialog-container-color: #F4F4F0;
  }
}
.mobile-notification-menu-panel {
  margin-right: 40px;
}
.roundedPanel {
  .mat-mdc-dialog-surface {
    --mdc-dialog-container-shape: 12px;
  }
}
@media screen and (max-width: 768px) {
  .mobile-mega-menu-panel {
    margin-top: 0px !important;
    margin-right: 0px !important;
    max-width: 100% !important;
    height: 100% !important;
    .mat-mdc-dialog-surface {
      --mdc-dialog-container-shape: 0px;
    }
  }
  .mobile-notification-menu-panel {
    margin-top: 0px !important;
    margin-right: 0px !important;
    max-width: 100% !important;
    height: 100% !important;
    .mat-mdc-dialog-surface {
      --mdc-dialog-container-shape: 0px;
    }
  }
}

.cdk-overlay-container:has(.popup-container) {
  z-index: 99999999;
  .mdc-dialog__surface {
    --mdc-dialog-container-shape: 0px;
  }
}

.mdc-snackbar__surface {
  padding: 0px !important;
  .mdc-snackbar__label {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}

.field-type-1 {
  &.invalid {
    .field-label, .field-input {
      color: red;
    }
    .field-input {
      background-color: #ffe7e7;
      border-color: #f00;
    }
  }
}

.arrow-btn {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border: 0px;
  border-radius: 50%;
  background-color: var(--light-button-hover-bg-color-1);
  outline: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all .2s ease-in-out;
  img {
    transition: all .2s ease-in-out;
  }
  span.title-hover {
    position: absolute;
    display: none;
    background-color: var(--light-main-color);
    font-size: 12px;
    font-weight: 100;
    line-height: 16px;
    padding: 1px 6px;
    border-radius: 3px;
    bottom: -27px;
    white-space: nowrap;
    color: var(--white);
  }
  &:hover {
    background-color: var(--light-button-hover-bg-color-1);
    span.title-hover{
      display: block;
    }
  }
  &:disabled {
    opacity: .5;
  }

  &.orange {
    background-color: #ffece4;
    img {
      filter: brightness(0) saturate(100%) invert(47%) sepia(62%) saturate(3413%) hue-rotate(345deg) brightness(101%) contrast(101%);
    }
    span.title-hover {
      background-color: #ffece4;
      color: var(--light-button-primary-bg-color);
    }
    &:hover {
      background-color: var(--light-button-primary-bg-color);
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%) hue-rotate(57deg) brightness(104%) contrast(101%);
      }
    }
  }

  &.green {
    background-color: var(--light-button-hover-bg-color-1);
    img {
      filter: brightness(0) saturate(100%) invert(45%) sepia(96%) saturate(1490%) hue-rotate(123deg) brightness(97%) contrast(101%);
    }
    &:hover {
      background-color: var(--light-button-hover-bg-color-1);
      img {
        filter: brightness(0) saturate(100%) invert(45%) sepia(96%) saturate(1490%) hue-rotate(123deg) brightness(97%) contrast(101%);
      }
    }
  }
}

.fav-btn {
  cursor: default;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--stepper-bg);
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border: 0px;
  border-radius: 50%;
  top: 5px;
  right: 5px;
  z-index: 1;
  img {
    filter: brightness(0) saturate(100%) invert(45%) sepia(96%) saturate(1490%) hue-rotate(123deg) brightness(97%) contrast(101%);
  }
}

.no-bg {
  background-color: transparent !important;
}

.mobile-notification-menu-backdrop + .cdk-global-overlay-wrapper,
.mobile-mega-menu-backdrop + .cdk-global-overlay-wrapper {
  width: 1100px;
  margin: 0 auto;
  position: relative;
  justify-content: flex-end !important;
}
.mobile-notification-menu-backdrop.full + .cdk-global-overlay-wrapper,
.mobile-mega-menu-backdrop.full + .cdk-global-overlay-wrapper {
  width: 100%;
  padding-right: 25px;
}

@media screen and (max-width: 1100px) {
  .mobile-notification-menu-backdrop + .cdk-global-overlay-wrapper,
  .mobile-mega-menu-backdrop + .cdk-global-overlay-wrapper {
    width: 100%;
  }
}

.mat-mdc-select-value {
  color: var(--light-main-color);
  font-weight: 400;
  font-size: 16px;
  width: 96% !important;
}

.align-bottom .mat-mdc-select-value-text {
  display: block;
  margin-top: 4px;
}


/*===========================================================================================*/
/*=================================== TEMPLATE FOR TOGGLE ====================================*/
/*===========================================================================================*/

/*
  <label class="custom-toggle">
    <input type="checkbox" (change)="changeEvent($event)" checked>
    <span class="slider round"></span>
  </label>
*/


.custom-toggle {
  position: relative;
  display: inline-block;
  width: 51px;
  min-width: 51px;
  height: 31px;
  min-height: 31px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
    &:checked + .slider {
      background-color: #02C39A;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
  }
  .slider {
    position: relative;
    cursor: pointer;
    background-color: #CED5CF;
    transition: 0.4s;
    border-radius: 15px;
    display: block;
    width: 51px;
    min-width: 51px;
    height: 31px;
    min-height: 31px;
    &::before {
      position: absolute;
      content: "";
      height: 27px;
      width: 27px;
      top: 2px;
      left: 2px;
      background-color: var(--white);
      transition: 0.4s;
      border-radius: 50%;
      display: block;
      box-shadow: 0px 3px 1px #0000000f;
    }
  }
}

/*===========================================================================================*/
/*=================================== TEMPLATE FOR CHECKBOX ====================================*/
/*===========================================================================================*/

/*
  <div class="custom-checkbox">
    <label class="checkbox-label">
      <input type="checkbox" name="checkbox" (change)="changeEvent($event)" checked>
      <span class="checkbox-check"></span>
      Checkbox
    </label>
  </div>
*/

.custom-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  .checkbox-label {
    display: flex;
    position: relative;
    padding-left: 0px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    color: var(--light-main-color);
    align-items: center;
    gap: 6px;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      display: non;
      &:checked ~ .checkbox-check {
        background-color: var(--light-main-color);
        &::after {
          content: "";
          width: 5px;
          height: 10px;
          border: 2px solid #fff;
          border-width: 0px 3px 3px 0px;
          transform: rotate(45deg) translate(-1.5px, -0.5px);
          display: block;
        }
      }
    }
    .checkbox-check {
      height: 19px;
      width: 19px;
      background-color: transparent;
      border-radius: 3px;
      display: flex;
      border: 2px solid var(--light-main-color);
      margin-top: -3px;
      position: relative;
      align-items: center;
      justify-content: center;
    }
  }
}


/*========================================================================================================*/
/*=================================== TEMPLATE FOR CHECKBOX WITH IMAGE====================================*/
/*========================================================================================================*/

/*
  <div class="custom-checkbox-image">
    <label class="checkbox-label">
      <input type="checkbox" name="checkbox" (change)="changeEvent($event)" checked>
      <span class="checkbox-check"></span>
      Checkbox
    </label>
  </div>
*/

.custom-checkbox-image {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  .checkbox-label {
    display: flex;
    position: relative;
    padding-left: 0px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    color: var(--light-main-color);
    align-items: center;
    gap: 6px;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      display: none;
      &:checked ~ .checkbox-check {
        background-image: url(./assets/icons/check-box-checked.svg);
      }
    }
    .checkbox-check {
      height: 19px;
      width: 19px;
      min-width: 19px;
      background-image: url(./assets/icons/check-box-unchecked.svg);
      display: flex;
      margin-top: -3px;
      position: relative;
      align-items: center;
      justify-content: center;
      background-size: contain;
    }
  }
}

/*===========================================================================================*/
/*=================================== TEMPLATE FOR RADIO ====================================*/
/*===========================================================================================*/

/*
  <label class="radio-label">
    <input type="radio" value="Value here" (change)="changeEvent($event)" name="radio">
    <span class="radio-check"></span>
    Radio Label
  </label>
*/
.radio-label {
  display: flex;
  position: relative;
  padding-left: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  color: var(--light-main-color);
  align-items: center;
  gap: 6px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    display: none;
    &:checked ~ .radio-check::after {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: var(--light-main-color);
      display: block;
      min-width: 12px;
      min-height: 12px;
    }
  }
  .radio-check {
    height: 19px;
    width: 19px;
    background-color: transparent;
    border-radius: 50%;
    display: inline-block;
    border: 2px solid var(--light-main-color);
    margin-top: -4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.time-value {
  .mdc-list-item__primary-text {
    white-space: nowrap !important;
  }
}

.custom-select-menu-dropdown {
  --mdc-menu-max-height: 400px;
  margin: 0px !important;
}


.blog-content {
  margin: 40px 0px;
  p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  a {
    color: var(--highlight-color);
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Recoleta';
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 60px;
  }

  b, strong {
    font-weight: 400;
  }
  ul, ol{
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    td {
      padding: 5px;
      p:last-child {
        margin: 0px !important;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .blog-content {
    padding: 0px 20px;
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}

.field-type-1.open .field-input,
.field-type-1 .field-input.open {
  border-radius: 4px 4px 0px 0px;
}

.clear-location {
  top: 47px;
  right: 15px;
  position: absolute;
  cursor: pointer;
}

.no-label .clear-location {
  top: 15px !important;
}

.accordion-group {
  .mat-expansion-panel-body  {
    padding: 15px 10px 10px;
  }
}

.searchboxPanel {
  height: auto !important;
  max-height: fit-content !important;
  .mdc-dialog__surface {
    overflow-y: initial !important;
  }
}

@media screen and (max-width: 550px) {
  .searchboxPanel {
    max-height: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .filtersBackdropClass + .cdk-global-overlay-wrapper {
    align-items: flex-end !important;
  }
  .filtersPanel {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.profile-header {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 4px solid var(--white);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  background-color: transparent;
  -webkit-box-shadow: none !important;
}

input:-webkit-autofill{
  -webkit-text-fill-color: var(--light-main-color) !important;
}

.faq-item {
  .mat-expansion-panel-body {
    padding: 14px;
  }
}

.__PrivateStripeElement {
  width: 100% !important;
}

.mat-content {
  margin-right: 20px !important;
}


.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  --mdc-checkbox-selected-checkmark-color: #fff;
}

.mat-calendar-body-cell:hover .mat-calendar-body-cell-content,
.mat-calendar-body-active .mat-calendar-body-cell-content {
  color: #fff;
}

.spin-animation {
  animation-name: spin-ani;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin-ani {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(-360deg);
  }
}

.mapboxgl-popup {
  max-width: 336px !important;
}

.mapboxgl-popup-content {
  min-width: 336px;
  max-height: 300px;
  overflow-y: auto;
  padding: 16px 16px 0;
}

.mapboxgl-popup-close-button {
  width: 16px;
  height: 16px;
  position: fixed;
  right: 4px;
  top: 2px;
}

.location-marker {
  position: relative;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-flow: column;
  clip-path: polygon(50% 0, 69% 2%, 81% 7%, 92% 16%, 100% 30%, 101% 39%, 100% 50%, 96% 60%, 90% 70%, 80% 81%, 69% 95%, 63% 105%, 40% 105%, 30% 95%, 18.5% 80%, 10% 70%, 4% 60%, 0 49%, -1% 40%, -1% 30%, 8% 16%, 20% 7%, 31% 2%);
  outline: none;
  -webkit-user-select: none;
  user-select: none;
  background-image: url('./assets/images/maps/pin-0.svg');
  background-size: 100% 100%;
  transform: scale(1);
  width: 28px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  transition: width 0.3s ease, height 0.3s ease;
  background-repeat: no-repeat;
  background-position: center;
  transform-origin: center bottom;
  transition: transform .32s cubic-bezier(.68,2.24,.56,.6), opacity .32s cubic-bezier(.68,2.24,.56,.6), clip-path .32s cubic-bezier(.68,2.24,.56,.6);
  &.pin-0 {
    background-image: url('./assets/images/maps/pin-0.svg');
  }
  &.pin-1 {
    background-image: url('./assets/images/maps/pin-1.svg');
  }
  &.pin-2 {
    background-image: url('./assets/images/maps/pin-2.svg');
  }
  &.pin-3 {
    background-image: url('./assets/images/maps/pin-3.svg');
  }
  &.pin-4 {
    background-image: url('./assets/images/maps/pin-4.svg');
  }
  &.pin-5 {
    background-image: url('./assets/images/maps/pin-5.svg');
  }
  &.pin-6 {
    background-image: url('./assets/images/maps/pin-6.svg');
  }
  &.pin-7 {
    background-image: url('./assets/images/maps/pin-7.svg');
  }
  &.pin-8 {
    background-image: url('./assets/images/maps/pin-8.svg');
  }
  &.pin-9 {
    background-image: url('./assets/images/maps/pin-9.svg');
  }
  &.pin-9p {
    background-image: url('./assets/images/maps/pin-9p.svg');
  }
  &:hover {
    transform: scale(1.6);
    z-index: 1;
  }
}
.small-dot-marker:not(.marker-highlight) {
  .location-marker {
    background-image: none;
    background-color: black;
    clip-path: none;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    line-height: 10px;
    padding: 2px 0px 0px 0px;
    &::after {
      content: attr(pracs);
    }
    &:hover {
      background-color: transparent;
      background-image: url('./assets/images/map-pin-1.svg');
      width: 28px;
      height: 36px;
      margin-bottom: 17px;
      &.pin-0 {
        background-image: url('./assets/images/maps/pin-0.svg');
      }
      &.pin-1 {
        background-image: url('./assets/images/maps/pin-1.svg');
      }
      &.pin-2 {
        background-image: url('./assets/images/maps/pin-2.svg');
      }
      &.pin-3 {
        background-image: url('./assets/images/maps/pin-3.svg');
      }
      &.pin-4 {
        background-image: url('./assets/images/maps/pin-4.svg');
      }
      &.pin-5 {
        background-image: url('./assets/images/maps/pin-5.svg');
      }
      &.pin-6 {
        background-image: url('./assets/images/maps/pin-6.svg');
      }
      &.pin-7 {
        background-image: url('./assets/images/maps/pin-7.svg');
      }
      &.pin-8 {
        background-image: url('./assets/images/maps/pin-8.svg');
      }
      &.pin-9 {
        background-image: url('./assets/images/maps/pin-9.svg');
      }
      &.pin-9p {
        background-image: url('./assets/images/maps/pin-9p.svg');
      }
      &::after {
        content: '';
      }
    }
  }
}
.marker-highlight {
  z-index: 99999 !important;
  .location-marker {
    transform: scale(1.6) !important;
  }
}

.more-menu-drop {
  .mdc-list {
    display: flex;
    flex-direction: column;
    border-radius: 0px;
    background: white;
    padding: 8px 0px 0px !important;
    button {
      padding: 10px;
      border: 0px;
      background: transparent;
      font-size: 16px;
      min-width: 160px;
      color: var(--light-main-color);
      &:not(:last-child) {
        border-bottom: 1px solid #efefef;
      }
    }
  }
}
.tableScroll{
  width: 100%;
  overflow-x: auto;
}



/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--light-secondary-color);
  color: var(--light-secondary-color);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--light-secondary-color);
  color: var(--light-secondary-color);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--light-secondary-color);
  color: var(--light-secondary-color);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #6E8886;
  }
  50%, 100% {
    background-color: #6e888666;
  }
}

.mdc-checkbox__ripple {
  display: none;
}

.tbl-width-1 {
  min-width: 450px !important;
}

.no-avail-txt {
  font-size: 14px;
  font-weight: 200;
  line-height: 1.5;
  display: inline-block;
  color: var(--light-secondary-color);
}

.business-dashboard-custom-marker {
  background-image: url('./assets/images/map-pin.png'); /* Replace with your custom image URL */
  background-size: cover;
  width: 24px;
  height: 30px;
  border-radius: 50%; /* Optional: make it circular */
}

.dess.search-input-box {
  .field-input {
    padding: 13px 10px 11px 38px;
    width: 100%;
    border: 0px;
    border-radius: 4px;
    background-color: transparent;
    font-size: 14px;
    color: var(--light-main-color);
    &::placeholder {
      color: var(--light-main-color);
    }
    &:focus {
      background-color: var(--light-button-hover-bg-color-1);
      &::placeholder {
        opacity: .5;
      }
    }
    &:hover {
      background-color: var(--light-button-hover-bg-color-1);
    }
  }
}

.questionareBD {
  background-color: rgba(0, 0, 0, 0.71);
}

.gallery-pop-modal {
  .mat-mdc-dialog-surface {
    --mdc-dialog-container-shape: 0px;
  }
}

.mat-calendar-body-in-range {
  .mat-calendar-body-cell-content {
    color: #FFFFFF !important;
  }
}

.cdk-overlay-pane:has(.payment-dropdown) {
  width: 300px !important;
}
.date-range-dd {
  .mat-datepicker-toggle .mdc-icon-button{
    width: 44px !important;
    height: 44px !important;
    padding: 9px 10px 10px 10px !important;
    margin: 0px !important;
  }
}

.remove-shadow-inside {
  width: 100%;
  .fields-group {
    padding: 0px !important;
    box-shadow: none !important;
  }
}