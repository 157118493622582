@mixin font($background-color, $font-family, $font-weight) {
  background-color: $background-color;
  font-family: $font-family;
  font-weight: $font-weight;
}

.main-heading-txt {
  font-family: ('Avenir', sans-serif);
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
}
.heading-txt {
  font-family: ('Avenir', sans-serif);
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
}
.heading-txt-2 {
  font-family: ('Avenir', sans-serif);
  font-size: 20px;
  line-height: 27px;
}
.heading-txt-3 {
  font-size: 32px;
  font-family: 'Recoleta';
  font-weight: 400;
  letter-spacing: 0.6px;
}
.confirm-text {
  font-family: ('Avenir', sans-serif);
  font-size: 24px;
  line-height: 20px;
  font-weight: 600;
}
.form-heading-text {
  font-family: ('Avenir', sans-serif);
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}
.primary-btn-txt {
  font-size: 18px;
  line-height: 27px;
  font-family: ('Avenir', sans-serif);
}
.mt-label {
  font-family: ('Avenir', sans-serif);
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}
.sub-title {
  font-weight: 200;
  font-size: 16px;
  line-height: 24px;
}
.mt-form-heading {
  font-family: ('Avenir', sans-serif);
  font-size: 22px;
  line-height: 24px;
}
.booking-card-heading {
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
}
.btn-txt {
  font-family: ('Avenir', sans-serif);
  font-size: 14px;
  line-height: 12px;
  font-weight: 400;
}
.subtitle-sml-txt {
  font-family: ('Avenir', sans-serif);
  font-size: 14px;
  line-height: 18px;
}
.subtitle-sml-txt-2 {
  font-size: 14px;
  line-height: 22px;
}
.subtitle-sml-txt-3 {
  font-size: 14px;
  line-height: 19px;
}
.avatar-txt {
  font-size: 26px;
  line-height: 36px;
}
.sml-txt {
  font-size: 12px;
  line-height: 16px;
}
